<template>
  <div class="resident">
    <HeaderMenu />
    <div class="container-fluid py-4">
      <h1 class="h5 fw-bold mb-3">{{ $t("__reportResident") }}</h1>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="exportReport">
            <div class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{
                $t("__residentName")
              }}</label>
              <div class="col-sm-6 col-md-4">
                <select class="form-select" v-model="from.resident_id" required>
                  <option :value="null">{{$t('__openThisSelectMenuResident')}}</option>
                  <template v-for="data in resident">
                    <option
                      :key="data.id"
                      :value="data.id"
                      v-if="data.name"
                    >
                      {{
                        ((data.device === null) ? '' : data.device.bed_number) + " / " + data.name
                      }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="">
              <label class="form-label fw-bold">{{ $t("__reportType") }}</label>
              <div class="d-flex align-items-center flex-wrap">
                <div class="form-check form-check-inline">
                  <input
                    v-model="from.report_type"
                    class="form-check-input"
                    type="radio"
                    id="dailyReport"
                    value="daily"
                    name="report_type"
                    required
                  />
                  <label class="form-check-label" for="dailyReport">{{
                    $t("__dailyReport")
                  }}</label>
                </div>
                <div
                  v-if="from.report_type === 'daily'"
                  class="d-inline-block pe-sm-4 mb-2"
                >
                  <VueCtkDateTimePicker
                    :label="$t('__startDate')"
                    v-model="from.date"
                    only-date
                    format="YYYY-MM-DD"
                    formatted="YYYY-MM-DD"
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    :max-date="dailyMaxDate"
                    :min-date="dailyMinDate"
                  />
                  <div class="text-danger" v-if="is_invalid.date">
                    {{ feedback_message.date }}
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="from.report_type"
                    class="form-check-input"
                    type="radio"
                    id="weeklyReport"
                    value="weekly"
                    name="report_type"
                    required
                  />
                  <label class="form-check-label" for="weeklyReport">{{
                    $t("__weeklyReport")
                  }}</label>
                </div>
                <div
                  v-if="from.report_type === 'weekly'"
                  class="d-inline-block pe-sm-4 mb-2"
                >
                  <VueCtkDateTimePicker
                    :label="$t('__startDate')"
                    v-model="from.date"
                    only-date
                    format="YYYY-MM-DD"
                    formatted="YYYY-MM-DD"
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    :max-date="weeklyMaxDate"
                    :min-date="weeklyMinDate"
                  />
                  <div class="text-danger" v-if="is_invalid.date">
                    {{ feedback_message.date }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="from.date || from.report_type === 'weekly'"
              class="mt-2 mb-3 text-dark"
            >
              <small>{{ $t("__timeRange") }}：</small>
              <small>
                {{ new Date(new Date(from.date).setHours(12, 0, 0)).format("yyyy-MM-dd W hh:mm") }}
              </small>
              <small>~</small>
              <small>
                {{
                  from.report_type === "weekly"
                    ? new Date(new Date(from.date).setHours(12, 0, 0))
                        .addDays(7)
                        .format("yyyy-MM-dd W hh:mm")
                    : new Date(new Date(from.date).setHours(12, 0, 0))
                        .addDays(1)
                        .format("yyyy-MM-dd W hh:mm")
                }}
              </small>
            </div>
            <div class="my-3">
              <label class="form-label fw-bold">{{ $t("__chartData") }}</label>
              <div>
                <div
                  v-if="from.report_type === 'daily'"
                  class="form-check form-check-inline mb-2"
                >
                  <input
                    v-model="dataTypeAll"
                    class="form-check-input"
                    type="checkbox"
                    id="dataTypeAll"
                    @click="clickDataTypeAll"
                  />
                  <label class="form-check-label" for="dataTypeAll">{{
                    $t("__checkAll")
                  }}</label>
                </div>
                <div
                  v-for="dataType in data_type"
                  :key="'data_type-' + dataType"
                  class="form-check form-check-inline mb-2"
                >
                  <input
                    v-model="from.data_type"
                    class="form-check-input"
                    :type="from.report_type === 'daily' ? 'checkbox' : 'radio'"
                    :id="'dataType-' + dataType"
                    :value="dataType"
                    :name="from.report_type === 'daily' ? false : 'data_type'"
                    :required="from.report_type === 'daily' ? false : true"
                  />
                  <label
                    class="form-check-label"
                    :for="'dataType-' + dataType"
                    >{{ $t(`__${dataType}`) }}</label
                  >
                </div>
              </div>
              <div class="text-danger" v-if="is_invalid.data_type">
                {{ feedback_message.data_type }}
              </div>
            </div>
            <div class="mb-3" v-if="notify_type_list.length">
              <label class="form-label fw-bold">{{
                $t("__showNotification")
              }}</label>
              <div class="mb-2">
                <span
                  v-for="(notifyType, index) in notify_type_list"
                  :key="'notify_type' + notifyType"
                >
                  <span v-if="index">、</span>
                  {{ $t(`__${notifyType}Notify`) }}
                </span>
              </div>
            </div>
            <button type="submit" class="btn btn-primary rounded-pill">
              {{ $t("__search") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import i18n from '@/lang/lang.js'
import { resident } from '@/http/api/resident.js'
import { mapState } from 'vuex'

export default {
  name: 'SearchReportResident',
  components: { HeaderMenu, VueCtkDateTimePicker },
  data () {
    return {
      resident: null,
      from: {
        resident_id: null,
        report_type: 'daily',
        date: new Date().format('yyyy-MM-dd'),
        data_type: ['restless', 'RR', 'bedStatus', 'heartRate', 'spo2', 'temp'],
        notify_type: ['LYB', 'LVB', 'leaveTheBedTimeOut', 'RR', 'restless', 'heartRate', 'spo2', 'temp'] // 'SITTimeOut'
      },
      data_type: ['restless', 'RR', 'bedStatus', 'heartRate', 'spo2', 'temp'],
      notify_type_class: {
        restless: ['restless'],
        RR: ['RR'],
        bedStatus: ['LYB', 'LVB', 'leaveTheBedTimeOut'],
        heartRate: ['heartRate'],
        spo2: ['spo2'],
        temp: ['temp']
      },
      dataTypeAll: true,
      notifyTypeAll: false,
      is_invalid: {
        date: false,
        data_type: false
      },
      feedback_message: {
        date: i18n.t('__dateIsRequired'),
        data_type: i18n.t('__atLeastOneCheckboxMustBeSelected')
      }
    }
  },
  computed: {
    ...mapState(['token', 'timezone']),
    notify_type_list () {
      var list = []
      if (this.from.report_type === 'daily') {
        this.from.data_type.forEach((dataType) => {
          this.notify_type_class[dataType].forEach((notifyType) => {
            list.push(notifyType)
          })
        })
      } else {
        if (!this.from.data_type) return list
        list.push(...this.notify_type_class[this.from.data_type])
      }
      return list
    },
    dailyMaxDate () {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      let maxDate = ''
      maxDate = hours >= 12
        ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD')
      return maxDate
    },
    dailyMinDate () {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      let minDate = ''
      minDate = hours >= 12
        ? vm.$getTimeZoneDate(new Date().addDays(-29), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-30), vm.timezone, 'YYYY-MM-DD')
      return minDate
    },
    weeklyMaxDate () {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      let maxDate = ''
      maxDate = hours >= 12
        ? vm.$getTimeZoneDate(new Date().addDays(-6), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-7), vm.timezone, 'YYYY-MM-DD')
      return maxDate
    },
    weeklyMinDate () {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      let minDate = ''
      minDate = hours >= 12
        ? vm.$getTimeZoneDate(new Date().addDays(-29), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-30), vm.timezone, 'YYYY-MM-DD')
      return minDate
    }
  },
  watch: {
    'token.headers.Authorization' (data) {
      if (data) {
        const vm = this
        vm.getResident()
        const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
        vm.from.date = hours >= 12
          ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
          : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD')
      }
    },
    'from.report_type': function (data) {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      if (data === 'daily') {
        vm.from.data_type = []
        vm.from.date = hours >= 12
          ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
          : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD')
      } else {
        vm.from.data_type = null
        vm.from.date = hours >= 12
          ? vm.$getTimeZoneDate(new Date().addDays(-6), vm.timezone, 'YYYY-MM-DD')
          : vm.$getTimeZoneDate(new Date().addDays(-7), vm.timezone, 'YYYY-MM-DD')
      }
      vm.is_invalid.date = false
      vm.is_invalid.data_type = false
    },
    'from.data_type': function (data) {
      if (!data) return
      this.is_invalid.data_type = false
      if (data.length === this.data_type.length) {
        this.dataTypeAll = true
      } else {
        this.dataTypeAll = false
      }
      this.from.notify_type = this.notify_type_list
    },
    'from.date': function () {
      this.is_invalid.date = false
    }
  },
  methods: {
    getResident () {
      const vm = this
      resident.get('', vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          vm.resident = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    clickDataTypeAll () {
      if (!this.dataTypeAll) {
        this.from.data_type = this.data_type
      } else {
        this.from.data_type = []
      }
    },
    exportReport () {
      const vm = this
      if (!vm.from.date) {
        vm.is_invalid.date = true
        return
      }
      if (vm.from.data_type && !vm.from.data_type.length) {
        vm.is_invalid.data_type = true
        return
      }
      vm.$router.push({ name: 'Graphical', query: vm.from })
    }
  },
  created () {},
  mounted () {
    const vm = this
    if (vm.token.headers.Authorization) {
      vm.getResident()
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      vm.from.date = hours >= 12
        ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD')
    }
  }
}
</script>

<style  lang="scss">
.resident {
  .card {
    position: relative;
    &-body {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/images/icon.svg");
      background-repeat: no-repeat;
      background-position: calc(100% + 20px) calc(100% + 20px);
      background-size: auto;
      opacity: 0.1;
      z-index: 0;
    }
  }
}
</style>
